import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import Modal from 'react-modal'
import Seo from '../../components/seo'
import Layout from '../../components/layout/layout'
import { LocaleProvider } from '../../hooks/useLocale'
import useTranslation from '../../hooks/useTranslation'
import { useLocation } from '@reach/router'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const CareerHero = loadable(() =>
  import('../../components/CareerHero/CareerHero')
)
const WorkProfile = loadable(() =>
  import('../../components/WorkProfile/WorkProfile')
)
const ContactForm = loadable(() => import('../../components/form/ContactForm'))
const Footer = loadable(() => import('../../components/footer/Footer'))

const Career = ({ pageContext: { locale } }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { pathname } = useLocation()
  const urlLang = pathname.split('/')[1]

  const translations = useTranslation()
  const [, setLangToStorage] = useLocalStorage()

  useEffect(() => {
    setLangToStorage(urlLang)
  }, [])
  const customStyles = {
    content: {},
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  }
  Modal.setAppElement('body')

  const {
    careerHeroTitlePartOne,
    careerHeroTitlePartTwo,
    careerHeroDescription,
  } = translations
  return (
    <>
      <Layout
        translations={translations}
        locale={locale}
        openModal={() => setModalOpen(true)}
      >
        <Seo locale={locale} translations={translations} title="Enterosoft" />
        <CareerHero
          heroTitlePartOne={careerHeroTitlePartOne}
          heroTitlePartTwo={careerHeroTitlePartTwo}
          description={careerHeroDescription}
        />
        <WorkProfile locale={locale} translations={translations} />
        <Footer
          translations={translations}
          locale={locale}
          openModal={() => setModalOpen(true)}
        />
      </Layout>
      <Modal className="contact__modal" style={customStyles} isOpen={modalOpen}>
        <ContactForm
          translations={translations}
          locale={locale}
          closeModal={() => setModalOpen(false)}
        />
      </Modal>
    </>
  )
}

const CareerPage = ({ children, pageContext }) => (
  <LocaleProvider>
    <Career pageContext={pageContext}>{children}</Career>
  </LocaleProvider>
)
export default CareerPage
